<template>
  <div class="openinghours container has-text-centered">
        <div v-if="pending" class="columns">
              <div class="column">
                  <div class="columns">
                      <div class="column"></div>
                    <div class="column is-half">
                    <b-image
                        :src="$axios.defaults.baseURL + openingHours.Logo.url"
                        alt="A random image"
                    ></b-image>
                    </div>
                    <div class="column"></div> 
                </div>
                <div v-if="showSpecialTimes" class="column">
                        <div class="notification is-danger">
                            <vue-simple-markdown class="textmarkdown" :source="specialTimeContent"></vue-simple-markdown>
                        </div>
                </div>
                
                <div class="column">
                        <div class="columns">
                            <div class="column is-hidden-mobile">
                                <div  id="austrianFlag"></div>
                            </div>
                            <div class="column is-three-fifths">
                                <h1 class="title">Öffnungszeiten</h1>
                                <vue-simple-markdown class="textmarkdown" :source="openingHoursContent"></vue-simple-markdown>
                            </div>
                            <div class="column is-hidden-mobile">
                                <div id="austrianFlag"></div>
                            </div>
                            
                        </div>
                </div>
            </div>
        </div>
        <div v-else class="box">
              <h1 class="title"> Loading . . .</h1>
        </div>
  </div>
</template>

<script>
export default {
    name:"openinghours",
    data(){
        return {
            pending: false,
            openingHours: null
        }
    },
    computed:{
        showSpecialTimes(){
            return this.openingHours.spezialOeffungszeitenAnzeigen
        },
        openingHoursContent() {
            return this.openingHours.Oeffnungszeiten
        },
        specialTimeContent(){
            return this.openingHours.spezialOeffungszeiten
        }
    },
    async mounted(){
        this.pending=false
        this.openingHours = await this.$get("/oeffnungszeiten")
        this.pending=true
    }
}
</script>

<style scoped>
#austrianFlag {
    height: 100%;
}
@media screen and (max-width: 450px) {
  .textmarkdown {
     max-width: 40ch;
  }
}
@media screen and (max-width: 320px) {
  .textmarkdown {
     max-width: 36ch;
  }
}
</style>